import PropTypes from "prop-types"
import { NavLink as RouteNavLink } from "react-router-dom"
import { Nav } from "react-bootstrap"

const SidebarNavItem = ({ item }) => {
  const hasChildren = item.children && item.children.length !== 0
  return (
    <Nav.Item className="sidebar-nav-item">
      <Nav.Link as={(props) => <RouteNavLink {...props} />} to={item.to}>
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
        {hasChildren && (
          <span className="sidebar-nav-item__expand material-icons">
            keyboard_arrow_right
          </span>
        )}
      </Nav.Link>
      {hasChildren && (
        <div className="sidebar-nav-item__children">
          {item.children.map((child) => {
            return (
              <Nav.Link
                to={{pathname: child.to}}
                as={(props) => <RouteNavLink {...props} />}
                key={child.name}
              >
                {child.name}
              </Nav.Link>
            )
          })}
        </div>
      )}
    </Nav.Item>
  )
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
}

export default SidebarNavItem
