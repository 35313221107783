import { FC, useMemo } from "react"
import { Nav } from "react-bootstrap"

import SidebarNavItem from "./SidebarNavItem"
import { useUserContext } from "../../../contexts/UserContext"

const SidebarNavItems: FC = () => {
  const { checkPermission, sites, dashboard } = useUserContext()
  const items = useMemo(() => {
    const items = []

    if (dashboard && checkPermission("bi-perimetre")) {
      items.push({
        title: "Tableau de bord",
        htmlBefore: '<i class="material-icons">leaderboard</i>',
        to: "/dashboard",
      })
    }

    items.push({
      title: "Vos actifs",
      htmlBefore: '<i class="material-icons">apartment</i>',
      to: "/building",
      children: sites.map((b) => ({
        name: b.name,
        to: `/building/${b.id}`,
      })),
    })

    if (checkPermission("ged-read")) {
      /*console.log("SITES:", sites)
      for (let item in sites) {
          let objectWithGed = sites[item]
          if (objectWithGed.ged) {
              console.log("Object with GED:", objectWithGed)
          }
      }*/

      items.push({
        title: "Vos fichiers",
        htmlBefore: '<i class="material-icons">topic</i>',
        to: "/documents",
        children: sites
          .filter((b) => b.ged)
          .map((b) => ({
            name: b.name,
            to: `/documents/${b.ged}`,
          })),
      })
    }

    if (checkPermission("changeLog-read")) {
      items.push({
        title: "Change Log",
        htmlBefore: '<i class="material-icons">chrome_reader_mode</i>',
        to: "/changelog"
      })
    }

    return items
  }, [checkPermission, sites, dashboard])

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {items.map((item) => (
          <SidebarNavItem key={item.to} item={item} />
        ))}
      </Nav>
    </div>
  )
}

export default SidebarNavItems
