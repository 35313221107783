import React from "react"
import { Dropdown, Navbar, Nav } from "react-bootstrap"
import { url } from "gravatar"

export default class UserActions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    this.toggleUserActions = this.toggleUserActions.bind(this)
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    })
  }

  componentDidMount() {
    let mail = ""

    // TODO: "this.props.user.attributes" doesn't exist after the very first login
    // (no idea why exactly), so we put this quick condition hack in order to get the email value.
    // It works but it should be rewritten to address this issue properly.
    if (!this.props.user.attributes) {
      mail = this.props.user.challengeParam.userAttributes.email
    } else {
      mail = this.props.user.attributes.email
    }

    const avatar = url(mail, { d: "identicon" })

    this.setState({
      mail,
      avatar,
    })
  }

  render() {
    if (!this.state.avatar) return null
    return (
      <Nav.Item>
        <Dropdown.Toggle
          as={Nav.Link}
          onClick={this.toggleUserActions}
          className="text-nowrap px-3"
        >
          <img
            className="user-avatar rounded-circle mr-2"
            src={this.state.avatar}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{this.state.mail}</span>
        </Dropdown.Toggle>
        <Navbar.Collapse
          open={this.state.visible}
          className="position-relative"
        >
          <Dropdown.Menu show={this.state.visible}>
            <Dropdown.Item onClick={this.props.signOut} className="text-danger">
              <i className="material-icons text-danger">&#xE879;</i> Se
              déconnecter
            </Dropdown.Item>
          </Dropdown.Menu>
        </Navbar.Collapse>
      </Nav.Item>
    )
  }
}
