import Breadcrumb from "../components/common/Breadcrumb"
import { Container } from "react-bootstrap"

const ChangeLog = (path: string) => {

  const base_path = "/documents"
  const crumbs = [{ name: "Vos fichiers", path: base_path }]

  return (
    <>
      <Container fluid style={{ margin: "14px 0px" }}>
        <Breadcrumb crumbs={crumbs}></Breadcrumb>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "0px",
          }}
        >
        </div>
      </Container>
      <div style={{margin: "24px"}}>
        Pour accéder aux fichiers d'un bâtiment, sélectionnez un bâtiment dans le menu de gauche.
      </div>
    </>
  )
}

export default ChangeLog
