import { Component } from "react"
import Loader from "../components/common/Loader"
import { withRouter } from "react-router-dom"
import { API } from "aws-amplify"

class Document extends Component<any, any> {
  constructor(props: any) {
    super(props)

    console.log("constructor this.props:", this.props)

    if (!this.props) {
      console.error("class Document: 'this.props' doesn't exist")
    }

    if (!this.props.location) {
      console.error("class Document: 'this.props.location' doesn't exist")
    }

    this.state = {
      error: false,
      isLoading: true,
      isInitialized: false,
      bulldozair_project_id: "",
      bulldozair_api_key: "",
      list_of_buildings: null
    }

    const project_id_from_url = this.props.match.params.id

    API.get("main", "/summary", {})
      .then((response) => {
          console.log("API.get('main /summary') response:", response)

          for (let building_index in response.buildings) {
            const building_ged = response.buildings[building_index].ged

            //console.log(building_index)

            if (building_ged === project_id_from_url) {
              //console.log("OK: " + building_ged)

              const bulldozair_project_id = response.buildings[building_index].bulldozair_url.project_id
              const bulldozair_api_key = response.buildings[building_index].bulldozair_url.api_key

              this.setState({
                error: false,
                isLoading: false,
                isInitialized: true,
                bulldozair_project_id: bulldozair_project_id,
                bulldozair_api_key: bulldozair_api_key,
                list_of_buildings: response.buildings
              })

              console.log("API.get(main /summary) this.state:", this.state)

              return
            }
          }
      })
  }


  componentDidUpdate(prevProps: any) {
    //@ts-ignore
    const location = this.props.location.pathname

    console.log("componentDidUpdate location:", location)

    const previousLocation = prevProps.location.pathname

    if (location !== previousLocation) {
      console.log("componentDidUpdate this.state:", this.state)
      console.log("componentDidUpdate this.props.location:", this.props.location)

      let project_id = ""
      let api_key = ""

      for (let building_index in this.state.list_of_buildings) {
        const building_item = this.state.list_of_buildings[building_index]

        if (building_item.ged && building_item.ged === this.props.match.params.id) {
          project_id = this.state.list_of_buildings[building_index].bulldozair_url.project_id
          api_key = this.state.list_of_buildings[building_index].bulldozair_url.api_key
        }
      }

      this.setState({
        ...this.state,
        error: false,
        isLoading: false,
        isInitialized: true,
        bulldozair_project_id: project_id,
        bulldozair_api_key: api_key
      })

    }
  }

  componentDidMount() {
    if (!this.state) {
      console.error("'componentDidMount() this.state' doesn't exist")
    }

    this.setState({
      ...this.setState,
      error: false,
      isLoading: false,
      isInitialized: false,
    })
  }

  render() {

    console.log("render this.state (1):", this.state)

    if (!this.state || !this.state.isInitialized) {
      return <Loader fullscreen></Loader>
    }

    console.log("render this.props.location:", this.props.location)

    let bulldozair_project_id = this.state.bulldozair_project_id
    let bulldozair_api_key = this.state.bulldozair_api_key

    if (!bulldozair_project_id || !bulldozair_api_key) {
      console.error("render No value for either 'bulldozair_project_id' or 'bulldozair_api_key'")
      return
    }

    let bulldozair_full_url = "https://embedded.bulldozair.com/documents-embedded?projectId=" + bulldozair_project_id + "&apiKey=" + bulldozair_api_key

    console.log("render this.state (2):", this.state)
    console.log("render bulldozair_project_id:", bulldozair_project_id)

    let content = null

    if (this.state.isLoading) {
      content = <Loader fullscreen></Loader>
    } else if (this.state.error) {
      content = (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          Une erreur est survenue.
        </div>
      )
    } else {
      content = (
          <div style={{height: '100%'}}>
              <iframe title="BulldozAIR" src={bulldozair_full_url} width="100%" height="100%"/>
          </div>
      )
    }

    return (
      <div
        style={{
          marginBottom: "10px",
          flex: "1",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {content}
      </div>
    )
  }
}


//const UserDetails = ({ username, email }: any) => {
//  const avatar = url(email, { d: "identicon" })
//  return (
//    <div className="user-details">
//      <img
//        className="user-avatar rounded-circle mr-2"
//        src={avatar}
//        alt="User Avatar"
//      />
//      {username}
//    </div>
//  )
//}

export default withRouter(Document)

